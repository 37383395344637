<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col sm="7">
            <h4 id="traffic" class="card-title mb-0">Movimientos</h4>
            <div class="small text-muted">Consultar movimientos contables.</div>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row v-if="table.mostrarFiltros">
          <b-col sm="12">
            <b-row>
              <b-col md="3">
                <label><b>Desde</b></label>
                <b-form-input type="date" size="sm" v-model="filters.date_start" placeholder="Fecha Desde"></b-form-input>
              </b-col>
              <b-col md="3">
                <label><b>Hasta</b></label>
                <b-form-input type="date" size="sm" v-model="filters.date_end" placeholder="Fecha Hasta"></b-form-input>
              </b-col>  
              <b-col sm="3" class="mt-4">
                <b-button variant="outline-dark" @click="filterReport()">
                  <b-icon icon="filter" size="sm" class="mr-1" style="font-size:15px;"></b-icon>              
                  Filtrar
                </b-button>
              </b-col>                                     
            </b-row>
          </b-col>
        </b-row>

        <b-row class="mt-3">
          <b-col>
            <b-table class="mb-0"
                    responsive="sm"
                    head-variant="dark"
                    :hover="true"
                    :small="true"
                    :fixed="false"                    
                    :fields="table.fields"
                    :filter="table.filter"
                    :current-page="table.currentPage"
                    :per-page="table.perPage"
                    :items="table.items"                                
                    selectable
                    select-mode="single"
                    @row-selected="onRowSelected"    
                    v-if="table.items.length">

              <template v-slot:row-details="row">
                <b-card>
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col md="6">
                          <b-table class="mb-0"
                              responsive="sm"
                              head-variant="dark"
                              :hover="true"
                              :small="true"
                              :fixed="false"
                              :items="tableSub.items"
                              :fields="tableSub.fields"                            
                              v-if="tableSub.items.length">                                                                                     

                            <template v-slot:cell(code)="data">
                              <div v-if="data.item.type=='Debe'">
                                <span>{{data.item.accounting_accounts_code}}</span>
                              </div>
                              <div v-if="data.item.type=='Haber'">
                                <span class="ml-5">{{data.item.accounting_accounts_code}}</span>
                              </div>
                            </template>  

                            <template v-slot:cell(name)="data">
                              <div v-if="data.item.type=='Debe'">
                                <span>{{data.item.accounting_accounts_name}}</span>
                              </div>
                              <div v-if="data.item.type=='Haber'">
                                <span class="ml-5">{{data.item.accounting_accounts_name}}</span>
                              </div>                                                          
                            </template>

                            <template v-slot:cell(debit)="data">
                              <b v-if="data.item.type == 'Debe'">
                                {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(data.item.total)}}
                              </b>
                            </template>

                            <template v-slot:cell(credit)="data">
                              <b v-if="data.item.type == 'Haber'">
                                {{Intl.NumberFormat('es-AR',{style:'currency',currency: getCurrency}).format(data.item.total)}}
                              </b>
                            </template>
                          </b-table>
                          <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </b-card>
              </template>

              <template v-slot:cell(date)="data">
                <div v-if="data.item.date">
                  {{ moment(data.item.date).format('DD MMMM YYYY') }}
                </div>
              </template>

              <template v-slot:cell(reference)="data">
                {{data.item.reference}}
              </template>

              <template v-slot:cell(observations)="data">
                {{data.item.observations}}
              </template>

              <template v-slot:cell(f_action)="data">
                <b-dropdown right text="Acción" size="sm" variant="outline-dark" class="pull-right" v-if="data.item.print">
                  <b-dropdown-header>Acciones</b-dropdown-header>
                </b-dropdown>
              </template>
            </b-table>
            <b-alert v-else variant="warning" show>No se encontraron registros</b-alert>
          </b-col>
        </b-row>
      </b-card>

      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <b-button type="button" variant="outline-dark" size="sm" @click="$router.go(-1)">
              <i class="fa fa-angle-double-left"></i>
              Volver
            </b-button>                        
          </b-col>
      
          <b-col>
            <nav v-if="table.tablePaginate">
              <b-pagination class="pull-right mb-0"
                              size="sm"
                              pills=""
                              :total-rows="table.tablePaginate.total"
                              :per-page="table.tablePaginate.per_page"
                              v-model="table.currentPage"
                              @input="filterReport()" />
            </nav>
          </b-col>  
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'  
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper' 
  import moment from 'moment'
  import Vue from 'vue'

  export default {
    data: () => {
      return {      
        access: {
          module_id: Modules.CONTABILIDAD,
          profile_id: Profiles.PERSONAL,
          view_reference: 'accountingEntries',
          elements: {}
        }, 
        table : {
          items: [],
          filters: null,
          fields: [            
            {key: 'date', label: 'Fecha', class: "align-middle"},
            {key: 'reference', label: 'Referencia', class: "align-middle"},
            {key: 'observations', label: 'Observaciones', class: "align-middle"},
            {key: 'f_action', label:'', class: "align-middle"},
          ],          
          mostrarFiltros: true,
          currentPage: 1,
          perPage: 0,
          tablePaginate: null,          
        },
        tableSub : {
          items: [],
          fields: [
            {key: 'code', label: 'Código', class:'align-middle'},
            {key: 'name', label: 'Nombre', class:'align-middle'},
            {key: 'debit', label: 'Debe', class:'align-middle text-right'},
            {key: 'credit', label: 'Haber', class:'align-middle text-right'},
            {key: 'f_action', label:'', class:'align-middle'},
          ],
          filter: null,
          mostrarFiltros: false,
          currentPage: 1,
          perPage: 50,
        },
        arr: {
          accounting: []
        },
        filters: {
          date_start: '',
          date_end: '',
        },     
      }
    },
    created () {     
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */
    },  
    mounted() {
      this.filterLoad()
      this.filterReport()
    },
    computed: {
      getCurrency() {
        return 'ARS'
      }
    },
    methods: {
      onRowSelected(item) {                
        this.arr.accounting.forEach((element, index) => {
          if(item.length){
            if(element.id == item[0].id) {
              this.openDetail(index)
            }
          }
        }) 
      },     
      openDetail(index) {
        this.table.rowSelected = index
        this.filterReport()
      }, 
      filterLoad() { 
        this.filters.date_start = moment().format('YYYY-MM-DD') 
        this.filters.date_end = moment().format('YYYY-MM-DD') 
      },
      filterReport(){
        let loader = this.$loading.show()   
            
        var result = serviceAPI.filtrarAsientos(this.filters, this.table.currentPage)        

        result.then((response) => {
          loader.hide()
          this.table.items = response.data.data

          this.arr.accounting = response.data.data                    

          if(this.table.rowSelected!=null) {
            this.table.items[this.table.rowSelected]._showDetails = true

            if(this.table.items[this.table.rowSelected].details) {
              this.tableSub.items = this.table.items[this.table.rowSelected].details
            }
          }  
        })
        .catch(error => {                         
          loader.hide()
          this.$awn.alert(Error.showError(error))
        });
      },
    }    
  }
</script>